import React from 'react';
import profiles from '../../data/profiles.json'
import Profile from '../../components/profile/profile.component';

import '../../styles/profile.scss';

export default function UserPage() {

  if (typeof window !== "undefined") {
    if(window.utag){
      window.utag.view({page_subcategory: "bio-page"})
    }
  }

  const name = "nicola-moore";
  const profile = profiles.find(({ machineName }) => name === machineName)

  return (
    <Profile
      profile={profile}
      hidePhone={true}
    />
  )
}
